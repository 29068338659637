<template>
  <div id="legalStatement" class="pa-4">
    <div class="d-flex justify-space-between ml-2 mb-6">
      <v-card class="" flat>
        <h1>Mentions légales | CGV | CGU</h1>
      </v-card>
    </div>
    <div class="scrollable_container ml-2 pr-2 pb-3">
      <v-expansion-panels class="bordered mb-5" flat>
        <v-expansion-panel>
          <v-expansion-panel-header class="header-padding">
            <h2 class="text-center mb-0">POLITIQUE DE CONFIDENTIALITÉ</h2>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="pr-8 pl-8">
            <div>
              <h2>Préambule</h2>
              <p>
                La plateforme MAORIE (ci-après « MAORIE » ou « notre plateforme
                ») est éditée par la société FOGO CAPITAL AVENIR, Société par
                actions simplifiée, dont le siège social est situé au 10 rue
                Labie, 75017 Paris, immatriculée au Registre du commerce et des
                sociétés de Paris sous le numéro 830 636 965 (ci-après « Nous
                »). Nous accordons la plus haute priorité et le plus grand soin
                à la protection de la vie privée et des Données Personnelles de
                nos utilisateurs. L’objet de la présente politique (ci-après «
                Politique de confidentialité ») est de Vous informer en tant que
                clients et utilisateurs de MAORIE (ci-après « Vous ») sur les
                traitements de vos données à caractère personnel que Nous
                effectuons dans le cadre de votre utilisation (ci-après «
                Données Personnelles ») ainsi que sur vos droits. Pour plus
                d'informations sur MAORIE, veuillez-Vous référer à nos
                Conditions Générales de Vente (CGV) et nos Conditions Générales
                d’Utilisation (CGU). Nous pouvons être amenés à modifier les
                termes de la présente Politique de confidentialité, entre autres
                pour suivre les exigences réglementaires ou si un nouveau
                traitement de Données Personnelles est mis en œuvre. Vous en
                serez informé par une mention spéciale sur la page d’accueil de
                MAORIE. La présente Politique de confidentialité est régie par
                et doit être interprétée conformément aux lois françaises et à
                toutes autres dispositions applicables dans l’Union Européenne.
                Pour toute question concernant la présente Politique de
                confidentialité ou pour toute demande ou plainte concernant vos
                Données Personnelles, Vous pouvez Nous contacter à l’adresse
                électronique suivante : protection-donnees@maorie.com Vous
                disposez en outre du droit d’introduire une plainte ou une
                réclamation sur la manière dont Nous collectons et traitons vos
                Données Personnelles auprès de la Commission Nationale de
                l’Informatique et des Libertés (CNIL), par courrier au 3 Place
                de Fontenoy - TSA 80715 - 75334 PARIS CEDEX 07, ou directement
                sur son site Internet
                <a href="https://www.cnil.fr/fr/plaintes"> ici</a>, ou si Vous
                résidez dans l'Espace Economique Européen, auprès de votre
                autorité locale de protection des données.
              </p>
              <h2>Qu’est-ce qu’une donnée personnelle ?</h2>
              <p>
                Une Donnée Personnelle désigne toute information ou ensemble
                d’informations permettant de Vous identifier, directement ou
                indirectement par référence à un identifiant ou à un ou
                plusieurs éléments qui vous sont spécifiques. Cela signifie que
                sont des Données Personnelles : votre nom, prénom, mail
                personnel ou professionnel, numéro de téléphone personnel ou
                professionnel, adresse postale, fonctions professionnelles,
                historique de navigation, préférences de paramétrage, etc.
                Peuvent également être considérés comme des Données Personnelles
                des identifiants uniques comme votre identifiant de connexion ou
                numéro client, l’adresse IP de votre ordinateur, l’adresse MAC
                de votre appareil ou des données concernant votre navigation sur
                notre plateforme.
              </p>

              <h2>
                Qui est le responsable de traitement, garant de la protection de
                vos données personnelles ?
              </h2>
              <p>
                Le responsable du traitement est l’entité qui détermine les
                finalités et les moyens du traitement de vos Données
                Personnelles et qui est responsable, vis-à-vis de Vous, du
                respect de la réglementation sur la protection des Données
                Personnelles.
              </p>
              <p>
                <b>En ce qui concerne uniquement votre utilisation de MAORIE</b
                >, Nous traitons vos Données Personnelles, en qualité de
                responsable du traitement, collectées par le biais de notre
                plateforme. Votre point de contact privilégié pour toute
                question relative à l’utilisation que Nous faisons de vos
                Données Personnelles peut être contacté par courrier
                électronique à :
                <a href="mailto:protection-donnees@maorie.com"
                  >protection-donnees@maorie.com.</a
                >
              </p>
              <p>
                <b
                  >En ce qui concerne la prospection commerciale que Vous pouvez
                  recevoir :</b
                >
                Nous attirons votre attention sur le fait que vos coordonnées
                professionnelles seront envoyées à notre société mère (NEXTROAD)
                à des fins de prospection commerciale et de marketing direct.
                NEXTROAD agit dans ce cas comme responsable du traitement. Vous
                avez un droit d’opposition à cette prospection. Vous pouvez vous
                référer à sa politique de confidentialité ici pour connaître les
                traitements effectués.
              </p>
              <p>
                <b
                  >En ce qui concerne les traitements que votre société, qui
                  vous permet d’avoir accès à notre plateforme dans le cadre de
                  vos activités professionnelles, peut faire de vos Données
                  Personnelles :</b
                >
                Votre société reste responsable de traitement indépendant.
              </p>
              <p>
                Cette Politique de confidentialité ne concerne que les
                traitements de vos Données Personnelles effectuées par Nous en
                tant que responsable de traitement. Pour tous les autres
                traitements, veuillez consulter les politiques de
                confidentialité respective des responsables de traitement.
              </p>

              <h2>
                Quelles Données Personnelles recueillons-nous à votre sujet ?
              </h2>
              <p>Nous collectons vos Données Personnelles soit :</p>
              <ul>
                <li>
                  <b
                    >Directement auprès de Vous lorsque Vous utilisez MAORIE et
                    nos services :
                  </b>
                  <ul>
                    <li>
                      <b>Coordonnées et information sur votre profil :</b> Votre
                      prénom, nom de famille, adresse électronique
                      professionnel, nom de votre organisation et votre intitulé
                      de poste pour Vous identifier lorsque Vous utilisez
                      MAORIE.
                    </li>
                    <li>
                      <b>Contenu géré par vous :</b> Votre prénom, nom de
                      famille, adresse électronique professionnel, nom de votre
                      organisation et votre intitulé de poste pour Vous
                      identifier lorsque Vous utilisez MAORIE.
                    </li>
                  </ul>
                </li>
                <li>
                  <b
                    >Automatiquement lorsque Vous accédez ou utilisez MAORIE,
                    par le biais de cookies, autres traceurs, ou logs, quel que
                    soit votre terminal de connexion :
                  </b>
                  <ul>
                    <li>
                      <b>Données techniques</b> telles que le terminal à l’aide
                      duquel Vous vous connectez, votre système d'exploitation,
                      votre type de navigateur, si Vous utilisez un proxy, la
                      localisation de votre terminal déduite de votre adresse
                      IP, la date et l’heure de connexion et de déconnexion, le
                      nombre de visite, les temps d’accès, …
                    </li>
                    <li>
                      <b>Informations de navigation</b> telles que vos
                      préférences de paramétrage, les pages que Vous consultez,
                      le détail de vos actions effectuées, les types de données
                      consultées, vos clics et mouvements de souris, la façon
                      dont Vous avez fait défiler les pages, et tout autre
                      information relative à la manière dont vous interagissez
                      avec nos contenus.
                    </li>
                  </ul>
                </li>
                <li>
                  <b
                    >Indirectement auprès d’autres filiales de notre groupe
                    NEXTROAD :
                  </b>
                  <ul>
                    <li>
                      <b>Coordonnées et informations sur votre profil :</b>
                      Votre nom, prénom, adresse électronique professionnelle,
                      nom de votre organisation et intitulé de poste peuvent
                      nous être communiqués par d’autres filiales de NEXTROAD
                      lorsque Vous émettez le souhait d’inclure MAORIE dans
                      l’une de vos études. Ces informations seront utilisées
                      pour vous créer un compte utilisateur.
                    </li>
                    <li>
                      <b>Informations sur votre expérience d'utilisateur:</b>
                      Nous utilisons des cookies tiers ou autres traceurs
                      similaires pour suivre votre utilisation et votre
                      expérience sur notre plateforme. Ces cookies et autres
                      traceurs collectent automatiquement plusieurs informations
                      de navigation citées ci-dessus. Nous analysons ensuite ces
                      informations pour tirer des conclusions et optimiser ainsi
                      qu’améliorer les performances et la convivialité de notre
                      plateforme.
                    </li>
                  </ul>
                </li>
              </ul>
              <p>
                <br /><b
                  >Pour en savoir plus sur les cookies et autres traceurs</b
                >
                que nous utilisons et comment les désactiver, veuillez consulter
                ci-dessous notre politique cookies.
              </p>
              <h2>
                Pour quelles raisons utilisons-nous vos Données Personnelles et
                sur quelle base légale nous fondons-nous ?
              </h2>
              <p>
                Conformément à la réglementation en vigueur, Nous ne collectons
                vos Données Personnelles que lorsque Nous disposons d'une base
                juridique et d’une finalité clairement identifiée pour le faire.
              </p>
              <p><b>Vos données personnelles sont collectées soit : </b></p>
              <ul>
                <li>
                  <b
                    >Sur la base de votre consentement, aux fins suivantes :
                  </b>
                  <ul>
                    <li>
                      <b
                        >Effectuer des analyses de données pour déduire des
                        tendances et améliorer en conséquence notre plateforme,
                        ainsi que sa performance, et nos services</b
                      >, sur la base des informations recueillies par les
                      cookies de performance et d'analyse présents sur MAORIE
                      mesurant vos interactions avec celle-ci.
                    </li>
                  </ul>
                </li>
                <li>
                  <b
                    >Sur la base de l'exécution d'un contrat, aux fins suivantes
                    :
                  </b>
                  <ul>
                    <li><b>Créer votre compte client.</b></li>
                    <li>
                      <b>Délivrer les services</b> pour lesquels Vous avez
                      souscrit.
                    </li>
                  </ul>
                </li>
                <li>
                  <b
                    >Sur la base de notre intérêt légitime, aux fins suivantes :
                  </b>
                  <ul>
                    <li>
                      <b
                        >Vous informer des modifications apportées aux CGV, CGU
                        ou à la présente Politique de confidentialité.</b
                      >
                    </li>
                    <li>
                      <b
                        >Traiter, de manière adéquate et en temps utile, vos
                        demandes, plaintes et suggestions concernant MAORIE</b
                      >, notamment lorsque Vous posez des questions directement
                      nos ingénieurs référents, commerciaux ou chargé d'affaire
                      qui vous accompagnent à chaque étape de votre étude, ou
                      lorsque vous contactez notre support informatique pour
                      toutes questions liées à l’utilisation de MAORIE ou à un
                      bug éventuel.
                    </li>
                    <li>
                      <b
                        >Administrer techniquement notre plateforme et veiller à
                        son bon fonctionnement</b
                      >
                      en utilisant des cookies strictement nécessaires ainsi que
                      des logs applicatifs permettant de tracer vos connexions
                      et gérer les incidents, et en effectuant de la
                      maintenance.
                    </li>
                    <li>
                      <b
                        >Envoyer vos données de contact professionnel à
                        NEXTROAD, notre maison mère, à des fins de prospection
                        commerciale et marketing direct.</b
                      >
                      Pour rappel, notre société mère sera responsable de
                      traitement pour la prospection commerciale. Vous
                      bénéficiez d’un droit d’opposition à cette prospection.
                      Pour plus d’information sur les traitements liés à la
                      prospection commerciale, référez-vous à sa politique de
                      confidentialité
                      <a
                        href="https://www.nextroad.com/politique-de-confidentialite/"
                        target="_blank"
                        >ici</a
                      >.<br />
                      Veuillez trouver à la fin de cette Politique de
                      confidentialité une case à cocher si vous souhaitez vous
                      opposer à l’envoi de vos Données Personnelles à des fins
                      de prospection. A défaut, vos Données Personnelles seront
                      envoyées à votre société mère.
                    </li>
                    <li>
                      <b
                        >Défendre nos intérêts en cas de litige ou d'action en
                        justice.</b
                      >
                    </li>
                  </ul>
                </li>
                <li>
                  <b>Sur la base d’une obligation légale :</b>
                  <p>
                    Nous pouvons également traiter vos Données Personnelles pour
                    Nous conformer à nos obligations légales e.g., obligations
                    comptables, fiscales, garanties légales ou commerciales,
                    assurances, audit, etc.
                  </p>
                </li>
              </ul>
              <h2>A qui vos données personnelles sont-elles divulguées ?</h2>
              <p>
                Dans le cadre de nos prestations de services, Nous pouvons
                transférer vos Données Personnelles ou y accorder l’accès à
                notre personnel ayant besoin d’en avoir connaissance dans le
                cadre de l’exercice de leurs fonctions.
              </p>
              <p>
                Nous pouvons également transférer ou donner accès à vos Données
                Personnelles à certains tiers :
              </p>
              <ul>
                <li>
                  <p>
                    <b
                      >Nos sous-traitants et prestataires intervenant pour des
                      raisons techniques et logistiques :</b
                    >
                  </p>
                  <p>
                    Nos sous-traitants et prestataires n’auront accès qu’aux
                    données strictement nécessaires à la délivrance de leurs
                    prestations.
                  </p>
                  <p>
                    Veuillez trouver ci-dessous la liste de nos sous-traitants
                    et prestataires.
                  </p>
                </li>
                <li>
                  <p>
                    <b
                      >Nos partenaires utilisant des cookies sur notre
                      plateforme</b
                    >
                    pour les analyses statistiques et de performance.
                  </p>
                  <p>
                    Veuillez-Vous référer à la liste fournie ci-dessous dans
                    notre Politique Cookies.
                  </p>
                </li>
                <li>
                  <p>
                    <b>Notre société mère, NEXTROAD,</b> pour la prospection et
                    le marketing direct.
                  </p>
                  <p>
                    Vous bénéficiez d’un droit d’opposition à cette prospection.
                    Veuillez trouver à la fin de cette Politique de
                    confidentialité une case à cocher si vous souhaitez vous
                    opposer à l’envoi de vos Données Personnelles à des fins de
                    prospection. A défaut, vos Données Personnelles seront
                    envoyées à votre société mère.
                  </p>
                  <p>
                    Pour plus d’information sur les traitements liés à la
                    prospection commerciale, référez-vous à sa politique de
                    confidentialité
                    <a
                      href="https://www.nextroad.com/politique-de-confidentialite/"
                      >ici</a
                    >.
                  </p>
                </li>
                <li>
                  <p>
                    <b
                      >Toute société tierce dans le cadre d’une
                      restructuration</b
                    >
                    qui rachèterait en tout ou partie notre société ou
                    fusionnerait avec notre société.
                  </p>
                </li>
                <li>
                  <p>
                    <b>Toute autorité, juridiction ou tout autre tiers</b>
                    lorsqu’une telle communication est requise par une loi, une
                    disposition réglementaire ou une décision judiciaire
                    européennes ou françaises, ou si cette communication est
                    nécessaire pour assurer la protection et la défense de nos
                    droits.
                  </p>
                </li>
              </ul>
              <p>
                En dehors de ces cas, vos Données Personnelles ne seront pas
                transférées ou rendues accessibles à un tiers sans votre
                consentement préalable.
              </p>
              <p><u>Nos sous-traitants</u></p>
              <table>
                <thead>
                  <tr>
                    <th>Catégorie de sous-traitants / prestataires</th>
                    <th>Localisation</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Prestataire développement et informatique</td>
                    <td>France</td>
                  </tr>
                  <tr>
                    <td>Prestataire d’accompagnement sur travaux</td>
                    <td>France</td>
                  </tr>
                  <tr>
                    <td>Prestataire logiciel et NoSQL</td>
                    <td>France</td>
                  </tr>
                  <tr>
                    <td>Prestataire d’hébergement</td>
                    <td>France</td>
                  </tr>
                </tbody>
              </table>
              <h2>
                Quels sont les transferts de données personnelles en dehors de
                l’Espace Economique Européen (EEE) que nous effectuons ?
              </h2>
              <p>
                Il n’y a aucun transfert hors EEE dans le cadre de nos
                prestations de service et du traitement de vos Données
                Personnelles.
              </p>
              <h2>
                Comment la sécurité de vos données personnelles est-elle assurée
                ?
              </h2>
              <p>
                Nous mettons en œuvre des
                <b
                  >mesures techniques et organisationnelles conformes aux
                  exigences légales et réglementaires françaises et
                  européennes,</b
                >
                permettant d’assurer la sécurité et la confidentialité de vos
                Données Personnelles.
              </p>
              <p>
                Nous nous assurons, aux termes d'engagements écrits, que nos
                prestataires et sous-traitants présentent des garanties et
                mettent en œuvre des mesures de sécurité suffisantes pour
                assurer la protection de vos Données Personnelles dont le
                traitement leur est confié conformément aux exigences de la
                réglementation applicable en matière de protection des données
                personnelles.
              </p>
              <p>
                Toutefois, en raison du caractère ouvert et non sécurisé
                d'Internet, Nous ne pouvons être responsables de la sécurité des
                transmissions de Données Personnelles sur Internet et Nous
                attirons votre attention sur les risques inhérents à
                l'utilisation de notre plateforme.
              </p>
              <h2>
                Quels sont vos droits en ce qui concerne le traitement de vos
                données personnelles, comment pouvez-Vous les exercer et nous
                contacter
              </h2>
              <p>
                <b
                  >En ce qui concerne le traitement de vos Données Personnelles,
                  Vous avez le droit, dans certaines circonstances définies par
                  la réglementation en matière de protection des données
                  personnelles :
                </b>
              </p>
              <li>
                <ul>
                  <li>
                    D'obtenir confirmation que Nous traitons vos Données
                    Personnelles et, le cas échéant, de recevoir une copie
                    desdites Données Personnelles, ainsi que toute information
                    pertinente sur la manière dont elles sont traitées (<b
                      >droit d'accès</b
                    >).
                  </li>
                  <li>
                    De Nous demander de rectifier ou de compléter toute Donnée
                    Personnelle, si elle est inexacte ou incomplète (<b
                      >droit de rectification</b
                    >).
                  </li>
                  <li>
                    D’obtenir l'effacement de vos Données Personnelles (<b
                      >droit d'effacement</b
                    >) :
                  </li>
                  <ul>
                    <li>
                      Lorsqu’elles ne sont plus nécessaires aux fins pour
                      lesquelles elles ont été collectées ;
                    </li>
                    <li>Lorsque vous avez retiré votre consentement ;</li>
                    <li>
                      Lorsque vous vous êtes opposé au traitement de vos Données
                      Personnelles ;
                    </li>
                    <li>
                      Lorsque vos Données Personnelles ont été traitées
                      illégalement ; ou,
                    </li>
                    <li>Pour respecter une obligation légale.</li>
                  </ul>
                  <p>
                    Veuillez noter que Nous ne sommes pas tenus de donner suite
                    à votre demande si le traitement de vos Données Personnelles
                    est nécessaire au respect d'une obligation légale, si Nous
                    avons un motif légitime pour le faire, ou pour la
                    constitution, l'exercice ou la défense d'une action en
                    justice. C’est par exemple le cas, si Nous estimons que Vous
                    avez violé nos Conditions Générales d’Utilisation ou nos
                    Conditions Générales de Vente.
                  </p>
                  <li>
                    De Nous demander la limitation du traitement (<b
                      >droit de limitation</b
                    >) :
                  </li>
                  <ul>
                    <li>
                      Lorsque l'exactitude de vos Données Personnelles est
                      contestée ;
                    </li>
                    <li>
                      Lorsque le traitement est illégal, mais Vous ne souhaitez
                      pas que vos Données Personnelles soient effacées ;
                    </li>
                    <li>
                      Lorsque vos Données Personnelles vous sont encore
                      nécessaire pour la constatation, l'exercice ou la défense
                      de droits en justice ;
                    </li>
                    <li>
                      Lorsque le traitement est encore nécessaire pour vérifier
                      l'existence de motifs impérieux à la suite de l'exercice
                      de votre droit d'opposition.
                    </li>
                  </ul>
                  <p>
                    L’exercice du droit de limitation signifie que Nous pourrons
                    seulement conserver vos Données Personnelles mais pas les
                    utiliser.
                  </p>
                  <p>
                    Veuillez toutefois noter que Nous pourrons continuer à les
                    utiliser lorsque :
                  </p>
                  <ul>
                    <li>Vous Nous avez donné votre consentement ;</li>
                    <li>
                      Nous en avons besoin pour la constatation, l'exercice ou
                      la défense de droits en justice, ou pour la protection des
                      droits d'une autre personne physique ou morale.
                    </li>
                  </ul>
                  <li>
                    D’exiger que Nous Vous transmettions vos Données
                    Personnelles sous une forme structurée et standard, ou de
                    les transférer ou de les faire transférer à un autre
                    responsable de traitement de votre choix, lorsque le
                    traitement de vos Données Personnelles est automatisé et
                    basé sur votre consentement ou un contrat et qu'il est
                    effectué par des moyens automatisés (<b
                      >droit à la portabilité</b
                    >).
                  </li>
                  <li>
                    De Vous opposer aux traitements de vos Données Personnelles,
                    pour des motifs liés à votre situation personnelle
                    particulière, lorsque ces traitements sont fondés sur notre
                    intérêts légitimes ou pour la prospection et le marketing
                    direct effectués par notre société-mère NEXTROAD (<b
                      >droit d'opposition</b
                    >).
                  </li>
                  <li>
                    De retirer votre consentement à tout moment et sans
                    justification lorsque le traitement de vos Données
                    Personnelles est fondé sur votre consentement (par exemple,
                    utilisation de cookies et autres traceurs, ...) (<b
                      >droit au retrait du consentement</b
                    >).
                  </li>
                  <li>
                    D’établir des directives, générales ou particulières, quant
                    à la manière dont Vous souhaitez que conservions,
                    supprimions et / ou communiquions vos Données Personnelles
                    après votre décès (<b
                      >droit d’établir des directives quant à la gestion de vos
                      Données Personnelles post-mortem</b
                    >). Une personne de votre choix peut être désignée pour
                    exécuter ces directives. En l’absence de telles directives,
                    vos héritiers auront tout de même la possibilité d’exercer
                    certains droits, en particulier le droit d’accès ainsi que
                    le droit d’opposition. Ce droit est applicable en France, et
                    dans tout autre pays de l'UE où des dispositions locales
                    obligatoires le prévoient.
                  </li>
                </ul>
              </li>
              <p>
                Pour répondre à vos demandes, Nous pouvons Vous demander de Nous
                fournir une preuve d'identité et, si nécessaire, des
                informations complémentaires ou des pièces justificatives.
              </p>
              <p>
                <b><u>Procédure à suivre pour exercer vos droits :</u></b>
              </p>
              <ul>
                <li>
                  Vous pouvez vous opposer aux communications de marketing
                  direct, en cochant la case à la fin de notre Politique de
                  confidentialité, à défaut vos Données Personnelles seront
                  envoyées à notre société mère aux fins de prospection. Dans ce
                  cas, il vous sera toujours possible de vous y opposer auprès
                  de cette société.
                </li>
                <li>
                  Vous pouvez retirer votre consentement pour l'utilisation de
                  cookies en utilisant notre outil de gestion des cookies.
                </li>
                <p>
                  Veuillez lire ci-dessous notre politique cookies pour plus
                  d'informations.
                </p>
                <li>
                  Vous pouvez également Nous contacter directement à :
                  <a href="mailto:protection-donnees@maorie.com"
                    >protection-donnees@maorie.com</a
                  >
                  Veuillez préciser clairement quel droit Vous souhaitez exercer
                  lorsque Vous Nous contactez. Vous pouvez également Nous
                  contacter si Vous avez des questions ou des requêtes
                  concernant le traitement de vos Données Personnelles, en
                  utilisant l'adresse électronique indiquée ci-dessus.
                </li>
              </ul>
              <p>
                Vous disposez en outre du droit d’introduire une réclamation
                auprès d’une autorité de contrôle. L’autorité de contrôle
                française est la Commission Nationale de l’Informatique et des
                Libertés (CNIL). Vous pouvez la contacter par courrier au 3
                Place de Fontenoy - TSA 80715 - 75334 PARIS CEDEX 07, ou
                directement sur son site Internet
                <a href="https://www.cnil.fr/fr/plaintes">ici</a>.
              </p>
              <h2>
                Combien de temps conservons-Nous vos Données Personnelles ?
              </h2>
              <p>
                Vos Données Personnelles sont conservées pendant toute la durée
                nécessaire aux finalités pour lesquelles elles ont été
                collectées. Une fois la durée de conservation atteinte, elles
                seront soit archivées à des fins de comptabilité ou de
                documentation suivant les exigences réglementaires, puis, ou
                immédiatement si elles ne sont pas archivées, détruites ou
                rendues anonymes.
              </p>
              <ul>
                <li>
                  <b
                    >Les Données Personnelles nécessaires à la création ainsi
                    que la gestion de votre compte et à la délivrance de nos
                    services</b
                  >
                  seront conservées tout au long de notre relation commerciale.
                  Elles seront supprimées 1 mois après la suppression de votre
                  compte, quelle que soit la raison de la suppression de votre
                  compte.
                </li>
                <li>
                  <b
                    >L’historique et le contenu de vos conversations avec nos
                    ingénieurs référents, commerciaux ou chargé d'affaire</b
                  >
                  seront conservés pendant toute la durée du projet puis
                  archivés pendant une durée d’un an avant d’être supprimés.
                </li>
                <li>
                  <b>L’historique et le contenu de vos tickets</b> créés lorsque
                  vous contactez notre support informatique seront conservés
                  pendant la durée de traitement du ticket puis archivés pendant
                  une durée d’un an avant d’être supprimés.
                </li>
                <li>
                  <b>Les logs</b> permettant de gérer les traces et les
                  incidents seront conservés pendant 6 mois maximum, sauf
                  obligation légale, ou risque particulièrement important.
                </li>
                <li>
                  <b
                    >Les cookies enregistrant vos choix (consentement,
                    paramétrage, refus)</b
                  >
                  seront conservés pendant 6 mois, ce qui signifie que Nous vous
                  demanderons à nouveau votre consentement tous les 6 mois.
                </li>
                <p>
                  Pour plus d'informations concernant les cookies et les durées
                  de conservation par cookies, veuillez-vous référer notre
                  politique cookies.
                </p>
                <li>
                  <b
                    >Les Données Personnelles relatives aux litiges et aux
                    plaintes</b
                  >
                  seront conservées pendant 5 ans à compter du litige ou de la
                  plainte si aucune action en justice n'est engagée, ou, pendant
                  la durée de la procédure jusqu’à la pleine exécution de la
                  décision de justice ou de l'accord de règlement.
                </li>
                <li>
                  <b
                    >Les Données Personnelles jointes à votre carte
                    d'identité,</b
                  >
                  qui peut Vous être demandée lorsque Vous exercez vos droits
                  d’accès ou de rectification, seront conservées pendant 1 an,
                  et pendant 3 ans, en archive, lorsque Vous exercez votre droit
                  d'opposition.
                </li>
              </ul>

              <h2>Politique Cookies</h2>
              <p>
                La plateforme MAORIE (ci-après « <b>MAORIE</b> » ou «
                <b>notre plateforme</b> ») est éditée par la société FOGO
                CAPITAL AVENIR, Société par actions simplifiée, dont le siège
                social est situé au 10 rue Labie, 75017 Paris, immatriculée au
                Registre du commerce et des sociétés de Paris sous le numéro 830
                636 965 (ci-après « <b>Nous</b> »). Nous utilisons des cookies
                et autres traceurs sur cette plateforme pour en assurer la
                sécurité, optimiser la gestion du trafic, et, mesurer ainsi
                qu’analyser l’audience. Cette politique cookies doit être lue
                conjointement avec notre politique de confidentialité disponible
                <a href="#">ici</a>. Elle définit notamment la manière dont nous
                utiliserons les cookies déposés sur notre plateforme ainsi que
                vos droits. Nous pouvons être amenés à modifier les termes de
                cette politique cookies, entre autres pour suivre les exigences
                réglementaires ou si un nouveau traitement est mis en œuvre
                (nouveau cookie, nouvelle finalité, …). Vous en serez informé
                par une mention spéciale sur la page d'accueil de votre compte.
                Cette politique cookies est régie et doit être interprétée
                conformément aux lois françaises et à toutes autres dispositions
                obligatoires applicables dans l'Union Européenne. Pour toute
                question concernant la présente politique cookies ou pour toute
                demande ou plainte concernant vos Données Personnelles, Vous
                pouvez Nous contacter à l’adresse électronique suivante :
                <a href="mailto:protection-donnees@maorie.com"
                  >protection-donnees@maorie.com</a
                >. Vous disposez en outre du droit d’introduire une plainte ou
                une réclamation sur la manière dont Nous collectons et traitons
                vos Données Personnelles auprès des la Commission Nationale de
                l’Informatique et des Libertés (CNIL) par courrier à 3 Place de
                Fontenoy - TSA 80715 - 75334 PARIS CEDEX 07, par téléphone au
                Tel : 01 53 73 22 22 ou directement sur son site internet
                <a href="https://www.cnil.fr/fr/plaintes">ici</a>.
              </p>
              <h2>Qu’est-ce qu’un cookie ?</h2>
              <p>
                Un cookie est un
                <b>petit fichier texte téléchargé sur votre appareil</b>
                (ordinateur, tablette, smartphone, ...),
                <b
                  >qui recueille et stocke un certain nombre vos Données
                  Personnelles</b
                >
                liées à votre navigation (e.g., vos préférences, vos actions,
                l’heure et le nombre de visites, vos informations de connexion,
                …) et permettant ensuite à la plateforme de reconnaître votre
                appareil.
              </p>
              <p>
                Tous les cookies ne sont pas identiques, ils peuvent être
                différents selon leur provenance ou leur durée de vie.
              </p>
              <ul>
                <li><b>Les cookies peuvent provenir de deux sources</b> :</li>
                <ul>
                  <li>
                    <b>Les cookies propriétaires (ou internes)</b> : Ils sont
                    créés par le site internet ou la plateforme que Vous visitez
                    ou utilisez et associés à son nom de domaine.
                  </li>
                  <li>
                    <b>Les cookies tiers</b> : Ils sont créés par des
                    fournisseurs tiers et placés par l’éditeur du site internet
                    ou de la plateforme que Vous visitez ou utilisez et sont
                    donc associés à un nom de domaine différent du site ou de la
                    plateforme sur lequel ou laquelle ils sont mis en œuvre.
                  </li>
                  <p>
                    En ce qui concerne nos partenaires tiers, nous n’avons pas
                    accès à leurs cookies, contrairement aux cookies internes
                    qui sont directement gérés par Nous. Par conséquent, ils
                    sont régis par leur politique de confidentialité, qui
                    peuvent être consultées sur leur site internet.
                  </p>
                  <p>
                    Vous trouverez en bas de cette Politique cookies la liste de
                    nos partenaires utilisant des cookies sur notre plateforme
                    et un lien hypertexte vers leur politique de
                    confidentialité.
                  </p>
                </ul>
                <li><b>Les cookies peuvent avoir deux types de durée</b> :</li>
                <ul>
                  <li>
                    <b>Les cookies de session</b> : Ils sont temporaires et
                    seront supprimés automatiquement lorsque Vous fermerez votre
                    navigateur.
                  </li>
                  <li>
                    <b>Les cookies persistants</b> : Ils ne seront pas supprimés
                    à la fermeture de votre navigateur mais une fois leur date
                    d’expiration atteinte, qui peut varier en fonction de
                    l’objectif du cookie. Vous pouvez également supprimer
                    manuellement ces cookies.
                  </li>
                </ul>
              </ul>
              <p>
                Vous trouverez de plus amples informations sur
                <a
                  href="https://www.cnil.fr/fr/site-web-cookies-et-autres-traceurs"
                  >https://www.cnil.fr/fr/site-web-cookies-et-autres-traceurs</a
                >
                et
                <a href="https://www.youronlinechoices.com/fr/"
                  >https://www.youronlinechoices.com/fr/</a
                >
              </p>
              <h2>Autres traceurs</h2>
              <p>
                <b
                  >Nous pouvons également utiliser d’autres traceurs communément
                  appelés balises web</b
                >, en combinaison avec les cookies, pour examiner vos actions
                sur MAORIE (collectivement « cookies »).
              </p>
              <p>
                Les balises web sont généralement des fichiers images invisibles
                placés sur un site internet ou une plateforme contenant un
                identifiant unique, dont la fonction est similaire à celle des
                cookies, mais qui contrairement à ces derniers ne sont pas
                enregistrés sur votre terminal mais intégrés de manière
                invisible aux pages du site internet ou de la plateforme.
              </p>
              <p>
                Les informations recueillies le sont automatiquement et seront
                stockées dans nos systèmes au sein de <b>fichiers de logs</b>.
              </p>
              <p>
                Ces autres traceurs sont également soumis à la présente
                Politique de cookies.
              </p>
              <h2>Pour quelles finalités utilisons-nous des cookies ?</h2>
              <p>
                <b
                  >Nous et nos partenaires utilisons des cookies sur MAORIE
                  afin</b
                >
                :
              </p>
              <ul>
                <li>
                  De faire fonctionner de manière sécurisée notre plateforme,
                </li>
                <li>
                  De mesurer et d’analyser les audiences, le trafic et les
                  performances.
                </li>
              </ul>
              <h2>Comment gérer les cookies ?</h2>
              <p>
                <b
                  >Le dépôt de cookies sur votre appareil est soumis à votre
                  consentement, sauf s'ils sont strictement nécessaires</b
                >
                puisque dans ce cas, leur utilisation sera basée sur notre
                intérêt légitime. Par conséquent, Vous avez le choix d'accepter
                ou de refuser certains des cookies que Nous utilisons, et ce à
                tout moment.
              </p>
              <h4>
                <u
                  >a. Accepter, refuser ou paramétrer les cookies lors de votre
                  première visite</u
                >
              </h4>
              <p>
                Dès votre arrivée sur notre plateforme, une bannière cookies
                s’affiche contenant des informations relatives à leur
                utilisation. Cette bannière Vous permet d’accepter ou de refuser
                tout ou partie des cookies que Nous utilisons.
              </p>
              <p>
                Si Vous refusez un ou plusieurs cookies, Vous ne pourrez pas
                profiter des fonctionnalités y afférents décrites ci-dessous.
              </p>
              <p>
                Vous pouvez changer vos préférences ou retirer votre
                consentement à tout moment en cliquant sur le lien « Paramètres
                des cookies » présent en bas de page de notre plateforme.
              </p>
              <p>
                Veuillez noter que le retrait de votre consentement ne compromet
                pas la licéité du traitement fondé sur votre consentement
                effectué avant ce retrait.
              </p>
              <p>
                Votre consentement ne s'applique qu’aux cookies utilisés sur
                MAORIE.
              </p>
              <h4>
                <u
                  >b. Accepter, refuser ou paramétrer les cookies via votre
                  navigateur ou votre smartphone</u
                >
              </h4>
              <p>
                Vous avez également la possibilité de gérer le dépôt de cookies
                à l’aide du paramétrage de votre navigateur qui Vous permet de
                les accepter ou de les refuser, soit systématiquement, soit en
                fonction de leur origine. Vous pouvez également le configurer de
                manière à être notifié de la présence d’un nouveau cookie avant
                son dépôt.
              </p>
              <p>
                Vous pouvez également modifier les paramètres de confidentialité
                de votre smartphone.
              </p>
              <p>
                <b
                  >L'outil de gestion des cookies diffère selon votre système de
                  navigation ou votre smartphone</b
                >
                :
              </p>
              <table>
                <tr>
                  <td>Internet Explorer™</td>
                  <td>
                    <a
                      href="http://windows.microsoft.com/fr-FR/windows-vista/Block-or-allow-cookies"
                      >http://windows.microsoft.com/fr-FR/windows-vista/Block-or-allow-cookies</a
                    >
                  </td>
                </tr>
                <tr>
                  <td>Safari™</td>
                  <td>
                    <a
                      href="https://support.apple.com/fr-fr/guide/safari/sfri11471/mac"
                      >https://support.apple.com/fr-fr/guide/safari/sfri11471/mac</a
                    >
                  </td>
                </tr>
                <tr>
                  <td>Google Chrome™</td>
                  <td>
                    <a
                      href="http://support.google.com/chrome/bin/answer.py?hl=fr&hlrm=en&answer=95647"
                      >http://support.google.com/chrome/bin/answer.py?hl=fr&hlrm=en&answer=95647</a
                    >
                  </td>
                </tr>
                <tr>
                  <td>Firefox™</td>
                  <td>
                    <a
                      href="https://support.mozilla.org/fr/kb/activer-desactiver-cookies-preferences"
                      >https://support.mozilla.org/fr/kb/activer-desactiver-cookies-preferences</a
                    >
                  </td>
                </tr>
                <tr>
                  <td>Opera™</td>
                  <td>
                    <a
                      href="http://help.opera.com/Windows/10.20/fr/cookies.html"
                      >http://help.opera.com/Windows/10.20/fr/cookies.html</a
                    >
                  </td>
                </tr>
                <tr>
                  <td>Apple</td>
                  <td>
                    <a href="https://support.apple.com/fr-fr/HT201265"
                      >https://support.apple.com/fr-fr/HT201265</a
                    >
                  </td>
                </tr>
                <tr>
                  <td>Android</td>
                  <td>
                    <a
                      href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DAndroid&hl=fr"
                      >https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DAndroid&hl=fr</a
                    >
                  </td>
                </tr>
              </table>
              <p>
                <b
                  >Sachez que vos choix de paramétrage sont susceptibles de
                  modifier votre navigation sur Internet et vos conditions
                  d'accès à certains services nécessitant l'utilisation de
                  cookies</b
                >. Par exemple, le refus des cookies strictement nécessaires
                peut Vous empêcher d'accéder à certains contenus de MAORIE ou
                d’utiliser certains de nos services en ligne.
              </p>
              <h2>Quelles catégories de cookies utilisons-nous ?</h2>
              <p>
                Les différents cookies que Nous utilisons ont des durées de
                conservation différentes à l’issue desquelles vos données ne
                seront plus stockées. Vous trouverez le détail de ces durées de
                conservation par cookies dans le tableau ci-dessous.
              </p>
              <p>
                Les cookies enregistrant vos choix (consentement, paramétrage,
                refus) seront conservés pendant 6 mois, ce qui signifie que Nous
                vous demanderons à nouveau votre consentement tous les 6 mois
              </p>
              <h4><u>a. Cookies strictement nécessaires</u></h4>
              <p>
                Nous utilisons un cookie strictement nécessaire au bon
                fonctionnement de MAORIE, à savoir un cookie d’authentification
                nous permettant de sécuriser l’accès à notre plateforme.
              </p>
              <p>
                Ce cookie ne peut être désactivé de nos systèmes car il est
                fondé sur notre intérêt légitime à assurer l'accessibilité
                sécurisée à notre plateforme et nos différents services.
              </p>
              <p>
                Vous pouvez toutefois configurer votre navigateur pour qu'il
                bloque ou Vous informe de l'existence de ce cookie, mais, en
                conséquence, vous ne pourrez pas accéder à notre plateforme.
              </p>
              <p><b>Les cookies strictement nécessaires utilisés sont </b>:</p>
              <table>
                <tr>
                  <th>Nom du cookie</th>
                  <th>Nom de domaine</th>
                  <th>Description</th>
                  <th>Durée de vie</th>
                </tr>
                <tr>
                  <td>N/A</td>
                  <td>auth.maorie.com</td>
                  <td>Jeton d'authentification</td>
                  <td>24H</td>
                </tr>
              </table>
              <h4><u>Cookies de performance et d'analyse</u></h4>
              <p>
                Nous et nos partenaires, Google et CloudFlare, utilisons ce type
                de cookies pour optimiser les ressources du réseau en gérant le
                trafic et pour améliorer les performances de notre plateforme et
                comprendre comment Vous interagissez avec elle (nombre de
                visites, pages consultées, temps passé, sources de trafic, pages
                les plus et les moins populaires, ...) afin d'effectuer des
                statistiques sur une base agrégée d’information et, le cas
                échéant, améliorer notre plateforme.
              </p>
              <p>
                <b
                  >L'utilisation de ces cookies est basée sur votre
                  consentement</b
                >. Si Vous refusez, Vous ne serez pas en mesure de bénéficier de
                l’accélération du temps de chargement d’une page et Nous ne
                serons pas en mesure de savoir quand est-ce que Vous avez visité
                notre plateforme ni connaître votre parcours de navigation sur
                celle-ci.
              </p>
              <p>
                <b
                  >Les cookies de performance et d'analyse utilisés sont les
                  suivants </b
                >:
              </p>
              <table>
                <tr>
                  <th>Nom du cookie</th>
                  <th>Nom de domaine</th>
                  <th>Description</th>
                  <th>Durée de vie</th>
                </tr>
                <tr>
                  <td>__cfduid</td>
                  <td>bootstrapcdn.com</td>
                  <td>
                    Cookie tiers associé aux sites utilisant CloudFlare, utilisé
                    pour accélérer le temps de chargement des pages. Il ne
                    contient pas d'informations d'identification de
                    l'utilisateur
                  </td>
                  <td>1 mois</td>
                </tr>
                <tr>
                  <td>__cfduid</td>
                  <td>ui-avatars.com</td>
                  <td>Idem</td>
                  <td>1 mois</td>
                </tr>
                <tr>
                  <td>_ga_P2W54Y76KT</td>
                  <td>.maorie.com</td>
                  <td>Cookie de suivi statistique de l’utilisation du site</td>
                  <td>14 mois</td>
                </tr>
              </table>
              <h2>Vos droits</h2>
              <p>
                Conformément à la Loi Informatique et Libertés du 6 janvier 1978
                modifiée et au RGPD, Vous disposez d’un droit d’accès, de
                rectification, d’opposition, de suppression et de limitation
                quant aux Données Personnelles collectées par les cookies et
                autres traceurs présents sur notre plateforme.
              </p>
              <p>
                Ces droits peuvent être exercés à tout moment en envoyant un
                mail à
                <a href="mailto:protection-donnees@maorie.com"
                  >protection-donnees@maorie.com</a
                >.
              </p>
              <p>
                Vous disposez en outre du droit d’introduire une réclamation
                auprès d’une autorité de contrôle.
              </p>
              <p>
                L’autorité de contrôle française est la Commission nationale de
                l’Informatique et des Libertés (CNIL), que vous pouvez contacter
                par courrier au 3 Place de Fontenoy - TSA 80715 - 75334 PARIS
                CEDEX 07, ou directement sur son site Internet
                <a href="https://www.cnil.fr/fr/plaintes">ici</a>.
              </p>
              <h2>
                Liste de nos partenaires utilisant des cookies et autres
                traceurs.
              </h2>
              <p>
                Comme indiqué ci-dessus, Nous ne sommes pas les seuls à déposer
                des cookies et autres traceurs sur MAORIE.
              </p>
              <p>
                Certains cookies sont déposés par nos partenaires, qui ont ainsi
                accès à vos Données Personnelles. Sachez que dans ce cas, les
                cookies sont gérés par leur politique de confidentialité.
              </p>
              <p><b>Nos partenaires tiers sont les suivants </b>:</p>
              <table>
                <tr>
                  <th>Nom du partenaire</th>
                  <th>
                    Description du cookies, indication de la localisation, lien
                    vers la politique
                  </th>
                </tr>
                <tr>
                  <td>Google</td>
                  <td>
                    Cookies d’analyse d'audience permettant d’analyser
                    l'utilisation faite de la plateforme Politique cookies :
                    <a
                      href="https://policies.google.com/technologies/cookies?hl=fr"
                      >https://policies.google.com/technologies/cookies?hl=fr</a
                    >
                  </td>
                </tr>
                <tr>
                  <td>Cloudflare</td>
                  <td>
                    Cookies de performance permettant d’accélérer le temps de
                    chargement des pages Politique cookies :
                    <a
                      href="https://support.cloudflare.com/hc/fr-fr/articles/200170156-Comprendre-les-cookies-de-Cloudflare"
                      >https://support.cloudflare.com/hc/fr-fr/articles/200170156-Comprendre-les-cookies-de-Cloudflare</a
                    >
                  </td>
                </tr>
              </table>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <v-expansion-panels class="mt-4 mb-5 bordered" flat>
        <v-expansion-panel>
          <v-expansion-panel-header class="header-padding">
            <h2 class="text-center mb-0">CONDITIONS GENERALES DE VENTE</h2>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="pr-8 pl-8">
            <div>
              <h2>Préambule</h2>
              <p>
                Les présentes Conditions Générales (ci-après les « CGV ») sont
                éditées par FOGO CAPITAL AVENIR, Société par actions simplifiée,
                au capital de 1 000,00 euros, immatriculée sous le numéro 830
                636 965 R.C.S. Paris, dont le siège est situé au 10 rue Labie
                75017 Paris, France (ci après « <b>FOGO</b> »).
              </p>
              <p>
                Les CGV sont destinées définir les modalités et conditions dans
                lesquelles FOGO fournit aux clients identifiés dans le bon de
                commande (ci après « <b>Utilisateurs</b> ») les services
                proposés (ci-après, les « <b>Services</b> ») de la plateforme
                MAORIE (ci-après : la « <b>Plateforme</b>»).
              </p>
              <p>
                Les présentes conditions générales constituent, avec le bon de
                commande signé par l’Utilisateur (ci-après : le «
                <b>Bon de Commande</b> ») un ensemble contractuel indissociable
                (ci-après : le « <b>Contrat</b> »).
              </p>
              <p>
                En cas de contradiction, le Bon de Commande prévaut sur les CGV.
              </p>
              <h2>Acceptation des CGV</h2>
              <p>
                La validation du Bon de Commande par l’Utilisateur entraîne son
                acceptation pleine et entière des stipulations du présent
                Contrat. L’acceptation du Contrat par l’Utilisateur ne peut être
                que pleine et entière. Toute adhésion sous réserve est
                considérée comme nulle et non avenue.
              </p>
              <h2>Services</h2>
              <h3>Acceptation des CGV</h3>
              <p>
                L’accès aux Services nécessite que l’Utilisateur s’inscrive sur
                le Site, choisisse des identifiants de connexion personnels et
                renseigne l’ensemble des informations marquées comme
                obligatoires sur le formulaire d’inscription. Toute inscription
                incomplète ne sera pas validée. L’Utilisateur garantit que
                toutes les informations qu’il renseigne sont exactes et
                sincères. Il s’oblige à les mettre à jour pendant l’utilisation
                des Services. A partir de son inscription validée, l’Utilisateur
                accède à un espace professionnel (ci-après : l’«Espace
                Professionnel») qui lui permet de gérer son utilisation des
                Services.
              </p>
              <h3>Utilisation des Services</h3>
              <p>
                es Services sont fournis uniquement à l’Utilisateur et destinés
                à l’usage exclusif de ou des établissements désignés dans le Bon
                de commande. L’Utilisateur s’engage à ne permettre à aucun tiers
                de les utiliser à sa place ou pour son compte, sauf à en
                supporter l’entière responsabilité. Il est pareillement
                responsable du maintien de la confidentialité de ses
                identifiants de connexion. Il doit immédiatement contacter FOGO
                aux coordonnées mentionnées dans le Bon de Commande, s’il
                remarque que son compte a été utilisé à son insu. Il reconnaît à
                FOGO le droit de prendre toutes mesures appropriées en pareil
                cas.
              </p>
              <h3>Description des Services</h3>
              <p>
                Maorie est une plateforme web de mise à disposition sécurisée de
                données liées à l’exploitation d’infrastructures, comportant de
                la datavisualisation et de la cartographie, et permettant
                d’échanger avec des ingénieurs sur les sujets techniques liés à
                l’exploitation des données.
              </p>
              <h3>Durée des Services</h3>
              <p>
                Le présent Contrat entre en vigueur à compter de l’acceptation
                du Bon de Commande par l’Utilisateur, sous réserve de son
                inscription dans les conditions définies à l’article 3 des
                présentes et du paiement de son abonnement, dans les conditions
                définies ci-dessous.
              </p>
              <p>
                Le Contrat est conclu pour une période initiale d’un an
                renouvelable tacitement pour une période successive de même
                durée, à défaut de dénonciation par l’une ou l’autre des parties
                adressées par courrier recommandé avec accusé de réception, au
                plus tard 30 jours avant l’échéance.
              </p>
              <p>
                A l’issue de cette période initiale, l’Utilisateur pourra mettre
                fin au contrat à tout moment, par anticipation et sans frais, en
                envoyant un courrier recommandé avec accusé de réception à FOGO
                avec un préavis d’un (1) mois.
              </p>
              <h2>Acceptation des CGV</h2>
              <p>
                Les Services sont souscrits dans le cadre d’un abonnement payant
                (ci-après : l’« <b>Abonnement</b> »), dont les conditions
                tarifaires sont indiquées dans le Bon de commande.
              </p>
              <h2>Confidentialité</h2>
              <p>
                L'expression « <b>Informations Confidentielles</b> » ci-après
                employée signifie toutes les informations qui seront
                communiquées comme telles aux Parties dans le cadre de
                l’exécution du Service et notamment, sans que cette liste soit
                limitative, les spécifications commerciales ou financières se
                rapportant aux CGV, et s’interdisent en conséquence de les
                divulguer, sous quelque forme que ce soit.
              </p>
              <p>
                Les Informations Confidentielles ne comprennent pas les
                informations (i) qui sont ou viendraient à être disponibles et
                connues du public sans que cela ne résulte d'une violation du
                Contrat, (ii) qui ont été licitement mises à disposition ou
                transmises aux Parties ou à leurs Représentants (tels que
                ci-après défini) par un tiers non tenu par une obligation de
                confidentialité.
              </p>
              <p>
                Les Parties se portent fort, l’une envers l’autre, du respect de
                cet engagement de confidentialité par (i) ses affiliés et (ii)
                les membres de leur personnel concernés, dirigeants, mandataires
                sociaux, employés, représentants, membres des comités internes,
                agents et conseillers externes, leurs éventuels préposés,
                sous-traitants, mandataires ou ayant-droits (ci-après les «
                <b>Représentants</b> »).
              </p>
              <p>
                Ces engagements, qui demeureront en vigueur pendant toute la
                période contractuelle, se poursuivront pendant une période
                expirant cinq (5) ans après la fin du Service pour quelque
                raison que ce soit, à moins que les informations visées ne
                tombent dans le domaine public.
              </p>
              <h2>Données Personnelles</h2>
              <p>
                Dans cette présente clause, les termes en majuscule ont la
                signification qui leur est donnée dans l’article 4 du Règlement
                (UE) 2016/679 du Parlement Européen etdu Conseil du 27 avril
                2016 relatif à la protection des personnes physiques à l'égard
                du traitement des données à caractère personnel et à la libre
                circulation de ces données, et abrogeant la directive 95/46/CE
                (règlement général sur la protection des données) (ci-après, le
                « <b>RGPD</b> »).
              </p>
              <p>
                L’exécution du Contrat entraine le Traitement de Données
                Personnelles par les parties aux fins de la gestion de leur
                relation commerciale et de la fourniture des Services.
              </p>
              <p>
                Ces Traitements sont soumis au RGPD et à toute règlementation
                applicable en matière de Données Personnelles. Les parties
                déclarent connaître leurs droits et obligations en vertu du RGPD
                et à toute règlementation applicable en matière de Données
                Personnelles et liés à ces Traitements.
              </p>
              <p>
                Les parties s’engagent à Traiter les Données Personnelles dans
                le respect du RGPD et de la règlementation applicable en matière
                de Données Personnelles. Elles s’engagent à Traiter les Données
                Personnelles de manière loyale et licite en toutes
                circonstances.
              </p>
              <p>
                Elles déclarent avoir respecté, et s’engagent à respecter
                pendant toute la durée du Contrat, toutes leurs obligations
                découlant du RGPD et de la règlementation applicable en matière
                de Données Personnelles, et notamment l’obligation de procéder à
                toute déclaration auprès des autorités en charge de la
                protection des données et à toute information des Personnes
                Concernées dans le cadre de la collecte et du Traitement de
                Données Personnelles aux fins des Services fournis au titre du
                Contrat.
              </p>
              <p>
                Il est précisé que le Client et FOGO interviennent en tant que
                Responsables du Traitement indépendants dans le cadre du
                Traitement de Données Personnelles aux fins de la gestion de
                leur relation commerciale.
              </p>
              <h2>Limitation de responsabilité</h2>
              <p>
                FOGO ne saurait être redevable envers l’Utilisateur du paiement
                de dommages et intérêts, de quelque nature qu’ils soient,
                directs, matériels, commerciaux, financiers ou moraux, en raison
                du recours par l’Utilisateur aux prestations, pour un montant
                supérieur aux montants facturés par FOGO en rémunération des
                prestations ayant entraîné sa responsabilité, au moment de la
                survenance du dommage prétendu.
              </p>
              <h2>Propriété intellectuelle</h2>
              <p>
                Les systèmes, logiciels, structures, infrastructures, bases de
                données et contenus de toute nature (textes, images, visuels,
                logos, marques, base de données, etc ...) exploités par FOGO au
                sein de la Plateforme sont protégés par tous droits de propriété
                intellectuelle ou droits des producteurs de bases de données en
                vigueur. Tous désassemblages, décompilations, décryptages,
                extractions, réutilisations, copies et plus généralement, tous
                actes de reproduction, représentation, diffusion et utilisation
                de l’un quelconque de ces éléments, en tout ou partie, sans
                l’autorisation de FOGO sont strictement interdits et pourront
                faire l’objet de poursuites judiciaires.
              </p>
              <h2>Références commerciales</h2>
              <p>
                L’Utilisateur autorise expressément FOGO à le citer et à
                utiliser, le cas échéant, la reproduction de sa marque ou de son
                logo à titre de références commerciales, notamment lors de
                manifestations ou d’événements, dans ses documents commerciaux
                et sur son site internet, sous quelque forme que ce soit.
              </p>
              <h2>Modification des CGV</h2>
              <p>
                Les présentes CGV pourront être modifiées ou complétées au cours
                de leurs exécution. Cependant, les modifications effectuées
                devront être formalisées par l’établissement d’un avenant de ces
                CGV, daté et signé par chacune des Parties.
              </p>
              <h2>Loi applicable – Litiges</h2>
              <p>
                Les présentes CGV sont soumises au droit français. Tout litige
                en relation avec la conclusion, l’exécution ou l’interprétation
                du présent Contrat sera confié aux tribunaux de Paris.
              </p>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <v-expansion-panels class="mt-4 bordered" flat>
        <v-expansion-panel>
          <v-expansion-panel-header class="header-padding">
            <h2 class="text-center mb-0">
              MENTIONS LÉGALES ET CONDITIONS GÉNÉRALES D'UTILISATION
            </h2>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="pr-8 pl-8">
            <h2>Présentation de la plateforme</h2>
            <p>
              <b>Propriétaire</b> : la plateforme MAORIE est la propriété de la
              société FOGO CAPITAL AVENIR, Société par actions simplifiée, au
              capital de 1 000,00 euros, immatriculée sous le numéro 830 636 965
              R.C.S. Paris, dont le siège est situé au 10 rue Labie 75017 Paris,
              France, avec le numéro individuel d'identification fiscale suivant
              : 830 636 965 R.C.S. Paris. Tél : Mél :
            </p>
            <p><b>Directeur de publication </b>:</p>
            <p><b>Hébergement de la plateforme </b>:</p>
            <p>SCALEWAY, 8 rue de la Ville l’Evêque, 75008 Paris</p>
            <p>Téle : +33 (0)1 84 13 00 00</p>
            <p>SIREN : 433 115 904 RCS Paris</p>
            <h2>Conditions générales d'utilisation de la plateforme</h2>
            <p>
              L’utilisation de la plateforme MAORIE implique l’acceptation des
              conditions générales d’utilisation ci-après décrites. Ces
              conditions d’utilisation sont susceptibles d’être modifiées ou
              complétées à tout moment. Les utilisateurs de la plateforme sont
              donc invités à les consulter de manière régulière.
            </p>
            <p>
              Ce site est normalement accessible à tout moment aux utilisateurs
              ayant créé un compte.
            </p>
            <p>
              Une interruption pour raison de maintenance technique peut être
              toutefois décidée. MAORIE s’efforcera alors de communiquer
              préalablement aux utilisateurs les dates et heures de
              l’intervention.
            </p>
            <p>
              L’ensemble de la plateforme relève de la législation
              internationale sur le droit d’auteur, le droit des marques et, de
              façon générale, sur la propriété intellectuelle, aussi bien en ce
              qui concerne sa forme (choix, plan, disposition des matières,
              moyens d’accès aux données, base de données ou toute organisation
              des données… ), qu’en ce qui concerne chacun des éléments de son
              contenu (textes, images, graphismes, logos, etc… ).
            </p>
            <p>
              Ces contenus, figurant sur les pages de ce site, sont la propriété
              exclusive de FOGO CAPTAL AVENIR. La reproduction de tout contenu
              publié sur le site est seulement autorisée aux fins exclusives
              d’information pour un usage personnel et privé. Toute
              reproduction, toute extraction ou toute utilisation de contenu
              réalisé à d’autres fins étant expressément interdites, sauf
              autorisation expresse de FOGO CAPTAL AVENIR.
            </p>
            <p>
              Toute reproduction, représentation, diffusion ou rediffusion, en
              tout ou partie, du contenu de la plateforme sur quelque support ou
              par tout procédé que ce soit (notamment par voie de caching,
              framing) de même que toute vente, revente, retransmission ou mise
              à disposition de tiers de quelque manière que ce soit sont
              interdites. Le non-respect de cette interdiction constitue une
              contrefaçon susceptible d’engager la responsabilité civile et
              pénale de son auteur.
            </p>
            <h2>Données personnelles</h2>
            <p>
              Nous veillons à la protection des données personnelles que vous
              nous transmettez. Nous vous invitons à consulter la charte «
              <b>Données personnelles</b> » qui décrit notre politique en
              matière de protection des données personnelles.
            </p>
            <h2>Responsabilité</h2>
            <p>
              FOGO CAPITAL AVENIR ne pourra être tenue responsable des dommages
              directs et indirects causés au matériel de l’utilisateur, lors de
              l’utilisation de la plateforme, et résultant soit de l’utilisation
              d’un matériel ne répondant pas aux spécifications indiquées au
              point 2, soit de l’apparition d’un bug ou d’une incompatibilité.
            </p>
            <p>
              FOGO CAPITAL AVENIR ne pourra également être tenue responsable des
              dommages indirects (tels par exemple qu’une perte de marché ou
              perte d’une chance) consécutifs à l’utilisation de la plateforme
              MAORIE.
            </p>
            <p>
              Des espaces interactifs (possibilité de poser des questions dans
              l’espace contact) sont à la disposition des utilisateurs. FOGO
              CAPITAL AVENIR se réserve le droit de supprimer, sans mise en
              demeure préalable, tout contenu déposé dans cet espace qui
              contreviendrait à la législation applicable en France, en
              particulier aux dispositions relatives à la protection des
              données. Le cas échéant, FOGO CAPITAL AVENIR se réserve également
              la possibilité de mettre en cause la responsabilité civile et/ou
              pénale de l’utilisateur, notamment en cas de message à caractère
              raciste, injurieux, diffamant, ou pornographique, quel que soit le
              support utilisé (texte, photographie…).
            </p>
            <h2>Droit applicable et juridique competente</h2>
            <p>
              Tout litige en relation avec l’utilisation de la plateforme MAORIE
              est soumis au droit français. Il est fait attribution exclusive de
              juridiction aux tribunaux compétents de Paris.
            </p>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </div>
</template>

<script>
export default {
  name: "legal-statement",
  data() {
    return {};
  },
  mounted() {},
  methods: {}
};
</script>

<style lang="scss" scoped>
#legalStatement {
  height: 100%;
  display: flex;
  flex-direction: column;
}
table,
td,
th {
  border: solid black 1px;
  border-collapse: collapse;
}

td,
th {
  padding: 5px;
}
h2,
h3,
h4 {
  margin-bottom: 20px;
}

table {
  margin-bottom: 20px;
}

p,
li {
  text-align: justify;
}

.bordered {
  border: thin solid rgba(0, 0, 0, 0.12);
}

.header-padding {
  padding: 8px;
}
</style>
